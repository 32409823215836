import React from 'react';
import Grid from "react-md/lib/Grids/Grid";
import Cell from "react-md/lib/Grids/Cell";
import Media from "react-md/lib/Media/Media";
import Card from "react-md/lib/Cards/Card";
import CardTitle from "react-md/lib/Cards/CardTitle";
import CardText from "react-md/lib/Cards/CardText";
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import Layout from '../layout';

const NotFoundPage = () => (

  <StaticQuery
    query={graphql`
      query {
        Image: file(relativePath: { eq: "404.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 600) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => (
      <Layout>
        <Grid stacked>
          <Cell
            align='middle'
            desktopSize={8}
            tabletSize={6}
          >
            <Media>
              <Img fluid={data.Image.childImageSharp.fluid} />
            </Media>
          </Cell>
          <Cell
            align='middle'
            desktopSize={8}
            tabletSize={6}
          >
            <Card>
              <CardTitle title="Error!" />
              <CardText>
                The page you were looking for couldnot be found...
              </CardText>
            </Card>
          </Cell>
        </Grid>
      </Layout>
    )}
  />
)

export default NotFoundPage
